<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <div class="row" v>
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <b-overlay :show="is_load">
                            <b-form @submit="save" v-if="form && form.empresa">
                                <div class="row justify-content-center">
                                    <div class="col-12 ">

                                        <h1><span v-if="is_novo">Nova empresa</span><span v-if="!is_novo">Editando empresa</span></h1>
                                        <hr>

                                        <div v-if="plano">
                                            <h3>{{ plano.name }}</h3>
                                            <div v-if="plano.plan_items && plano.plan_items.length > 0">{{ plano.plan_items[0].product.description }}</div>
                                        </div>
                                        <div v-if="!plano">
                                            <h3>Sem plano</h3>
                                        </div>
                                        <br>
                                        <br>

                                        <h4>Dados empresa<br><span>PJ</span></h4><br>


                                        <div class="row">
                                            <div class="col-md-4">
                                                <b-form-group label="CNPJ" class="required">
                                                    <b-form-input v-mask="['##.###.###/####-##']" v-model="form.empresa.cnpj" placeholder="" required></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-8">
                                                <b-form-group label="Nome Fantasia" class="required">
                                                    <b-form-input v-model="form.empresa.fantasia" placeholder="" required></b-form-input>
                                                </b-form-group>
                                            </div>

                                            <div class="col-md-7">
                                                <b-form-group label="Razão Social" class="required">
                                                    <b-form-input v-model="form.empresa.razao" placeholder="" required></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-5">
                                                <b-form-group label="Site" class=" ">
                                                    <b-form-input v-model="form.empresa.site" placeholder=""  ></b-form-input>
                                                </b-form-group>
                                            </div>
                                        </div>


                                        <div class="row">
                                            <div class="col-md-2">
                                                <b-form-group label="CEP" class=" xrx">
                                                    <b-form-input @change="buscaCep" v-mask="['#####-###']" v-model="form.empresa.cep" placeholder=""  xrx></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-5">
                                                <b-form-group label="Endereço" class=" xrx">
                                                    <b-form-input v-model="form.empresa.endereco" placeholder=""  xrx></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-2">
                                                <b-form-group label="Número" class=" xrx">
                                                    <b-form-input v-model="form.empresa.numero" placeholder=""  xrx></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-3">
                                                <b-form-group label="Complemento" class="">
                                                    <b-form-input v-model="form.empresa.complemento" placeholder=""></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-4">
                                                <b-form-group label="Bairro" class=" xrx">
                                                    <b-form-input v-model="form.empresa.bairro" placeholder=""  xrx></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-4">
                                                <b-form-group label="Cidade" class=" xrx">
                                                    <b-form-input v-model="form.empresa.cidade" placeholder=""  xrx></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-4">
                                                <b-form-group label="Estado" class=" xrx">
                                                    <b-form-input v-model="form.empresa.estado" placeholder=""  xrx></b-form-input>
                                                </b-form-group>
                                            </div>


                                            <div class="col-md-6">
                                                <b-form-group label="Área de Atuação" class=" xrx">
                                                    <b-form-select :options="areaAtuacaoOptions" v-model="form.empresa.area_atuacao" placeholder=""  xrx></b-form-select>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-6">
                                                <b-form-group label="Receita Bruta Anual" class=" xrx">
                                                    <b-form-select :options="receitaOptions" v-model="form.empresa.receita" placeholder=""  xrx></b-form-select>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-6">
                                                <b-form-group label="Número de funcionários" class=" xrx">
                                                    <b-form-select :options="funcionariosQtdOptions" v-model="form.empresa.funcionarios_qtd" placeholder=""  xrx></b-form-select>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-6">
                                                <b-form-group label="Logo" class="required logo-label"></b-form-group>
                                                <b-form-file @change="configUpload" v-model="logo" placeholder=""></b-form-file>
                                                <p>Envie seu logo em JPG no formato 110x70 pixels</p>
                                            </div>
                                        </div>


                                        <b-form-group label="Descrição resumida da empresa" class="xrx">
                                            <b-form-textarea v-model="form.empresa.descricao" placeholder="" xrx></b-form-textarea>
                                        </b-form-group>

                                    </div>
                                    <div class="col-12 ">
                                        <br>
                                        <h4>Dados para contato interno<br><span>entre a ABComm e o associado</span></h4>
                                        <br>


                                        <div class="row">
                                            <div class="col-sm-3">
                                                <b-form-group label="Nome (pessoa de contato)" class="xrx">
                                                    <b-form-input v-model="form.pessoa.nome" placeholder="" xrx></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <b-form-group label="CPF" class="xrx">
                                                    <b-form-input v-mask="['###.###.###-##']" v-model="form.pessoa.cpf" placeholder="" xrx></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <b-form-group label="Cargo" class="xrx">
                                                    <b-form-input v-model="form.pessoa.cargo" placeholder="" xrx></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <b-form-group label="E-mail" class="xrx">
                                                    <b-form-input v-model="form.pessoa.email" placeholder="" xrx></b-form-input>
                                                </b-form-group>
                                            </div>

                                            <div class="col-sm-4">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <b-form-group label="DDD" class="xrx">
                                                            <b-form-input v-mask="['##','###']" v-model="form.pessoa.ddd" placeholder="" xrx></b-form-input>
                                                        </b-form-group>
                                                    </div>
                                                    <div class="col-md-9">
                                                        <b-form-group label="Fone" class="xrx">
                                                            <b-form-input v-mask="['####-####','#####-####']" v-model="form.pessoa.fone" placeholder="" xrx></b-form-input>
                                                        </b-form-group>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12"><br><br><br>
                                                <h3>Uso interno</h3></div>
                                            <div class="col-sm-3">
                                                <b-form-group label="Habilita plano manual?" class="required">
                                                    <b-form-select :options="planoManual" v-model="formPlanoManual.ativo" required></b-form-select>
                                                </b-form-group>
                                                <br><br><br><br><br>
                                            </div>
                                            <div v-if="formPlanoManual.ativo==1" class="col-sm-3">
                                                <b-form-group label="Vencimento" class="required">
                                                    <b-form-datepicker v-model="formPlanoManual.vencimento" placeholder=""></b-form-datepicker>
                                                </b-form-group>
                                            </div>
                                            <div v-if="formPlanoManual.ativo==1" class="col-sm-3">
                                                <b-form-group label="ID Plano vindi" class="required">
                                                    <b-form-select :options="planoManualPlanoVindi" v-model="formPlanoManual.id_plano" placeholder=""></b-form-select>
                                                </b-form-group>
                                            </div>

                                        </div>

                                    </div>
                                    <div class="col-12 text-center">
                                        <b-button variant="outline-primary" size="lg" type="submit">Salvar cadastro</b-button>
                                    </div>
                                </div>


                            </b-form>
                        </b-overlay>
                    </div>
                </div>
            </div>
        </div>

    </Layout>
</template>

<script>

import axios from "axios";
import Vue from 'vue'
import Layout from "../layouts/main";
import PageHeader from "@/components/page-header";
import firebase from 'firebase/app';
import {isCPF, isCNPJ} from 'validation-br'

export default {
    name: "associados",
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            logo: null,
            is_load: false,
            is_novo: true,
            formPlanoManual: {
                ativo: 0,
                vencimento: null,
                id_plano: null,
            },
            form: {
                pago: false,
                id_plano: null,
                empresa: {
                    fantasia: null,
                    razao: null,
                    cnpj: null,
                    site: null,
                    cep: null,
                    endereco: null,
                    numero: null,
                    complemento: null,
                    bairro: null,
                    cidade: null,
                    estado: null,
                    area_atuacao: null,
                    receita: 1,
                    funcionarios_qtd: 1,
                    descricao: null,
                    logo: null,
                },
                pessoa: {
                    nome: null,
                    cpf: null,
                    cargo: null,
                    email: null,
                    ddd: null,
                    fone: null,
                },
                cartao: {
                    nome: null,
                    email: null,
                    documento: null,
                    card: {
                        cardName: "",
                        cardNumber: "",
                        cardMonth: "",
                        cardYear: "",
                        cardCvv: "",
                        cardInstallments: 1,
                        cardCompany: "",
                        cardPaymentMethodCode: "credit_card",
                    },
                },


            },
            title: "Associado",
            items: [
                {
                    text: "Home"
                },
                {
                    text: "Associado",
                    active: true
                }
            ],

            planoManualPlanoVindi: [
                {value: 1, text: "plano-anual-master"},
                {value: 2, text: "plano-anual-mantenedor"}
            ],
            planoManual: [{value: 0, text: "Não ativar plano manual"},
                {value: 1, text: "Sim, ativar plano manual"},],
            funcionariosQtdOptions: [{value: 1, text: "Até 9 Funcionários"},
                {value: 2, text: "De 10 a 49 Funcionários"},
                {value: 3, text: "De 50 a 99 Funcionários"},
                {value: 4, text: "Acima de 100 Funcionários"},],
            receitaOptions: [{value: 1, text: "Até R$ 60.000,00"},
                {value: 2, text: "De R$ 60.000,00 até R$ 360.000,00 "},
                {value: 3, text: "De R$ 360.000,01 até R$ 3.600.000,00"},
                {value: 4, text: "Acima de R$ 3.600.000,00"},
                {value: 5, text: "Prefiro não dizer"},],
            areaAtuacaoOptions: [{value: 13, text: "Alimentos e Bebidas"},
                {value: 14, text: "Arte e Antiguidades"},
                {value: 15, text: "Artigos Religiosos"},
                {value: 12, text: "Automoveis/Pecas"},
                {value: 16, text: "Bebes e Cia"},
                {value: 17, text: "Brinquedos e Games"},
                {value: 18, text: "Casa e Decoracao"},
                {value: 1, text: "Certificação Digital"},
                {value: 19, text: "Colecionaveis"},
                {value: 2, text: "Comparador de Preços"},
                {value: 20, text: "Compra Coletiva/Clubes de Compras"},
                {value: 21, text: "Construção e Ferramentas"},
                {value: 22, text: "Conteúdo Digital"},
                {value: 23, text: "Cosméticos, Perfumaria e Cuidados Pessoais"},
                {value: 3, text: "Educação e eventos"},
                {value: 24, text: "Eletrõnicos/Eletrodomésticos"},
                {value: 25, text: "Entretenimento"},
                {value: 26, text: "Esporte e Lazer"},
                {value: 27, text: "Flores, Cestas e Presentes"},
                {value: 28, text: "Fotografia"},
                {value: 29, text: "Informática"},
                {value: 4, text: "Informações de Mercado"},
                {value: 30, text: "Instrumentos Musicais"},
                {value: 31, text: "Joalheria"},
                {value: 32, text: "Livros"},
                {value: 33, text: "Loja de departamentos"},
                {value: 34, text: "Loterias e Apostas"},
                {value: 5, text: "Meios de Pagamento"},
                {value: 11, text: "Midia e Marketing Online"},
                {value: 35, text: "Moda e Acessórios"},
                {value: 36, text: "Musica"},
                {value: 10, text: "Outros"},
                {value: 37, text: "Papelaria e Escritorio"},
                {value: 38, text: "Pet Shop"},
                {value: 6, text: "Plataformas de Ecommerce"},
                {value: 7, text: "Portal"},
                {value: 39, text: "Saude"},
                {value: 40, text: "Servicos"},
                {value: 41, text: "Sex Shop"},
                {value: 8, text: "Softwares"},
                {value: 42, text: "Telefonia/Celulares"},
                {value: 9, text: "Varejo"},
                {value: 43, text: "Viagens e Turismo"}],
            plano: " "
        };
    },
    mounted() {
        this.loadAssociados();


        if(this.form.id_plano == 1 || this.form.id_plano == 2){
            this.formPlanoManual.ativo = this.form.id_plano;
        }

    },
    methods: {
        clearUrl(url){
            try{
                url = url.toLowerCase();
                url = url.replace('http://','')
                url = url.replace('https://','')
                url = url.replace('www.','')
                url = url.replace('@','')
                for(var x=0;x<url.length;x++){
                    if(url.substring(url.length-1,url.length) == '/' || url.substring(url.length-1,url.length) == '.')
                        url = url.substring(0,url.length-1)
                }
                url = url.split('/')[0];
                url = url.split(',')[0];
                url = url.split('(')[0];
                url = url.split(')')[0];
                url = url.split(' ')[0];
                return url.trim();
            }catch (e){
                return url;
            }
        },
        getPlano() {
            console.log('getPlano',this.form);
            var idPlano = 0;
            try {
                if (this.form.id_plano_vindi && this.form.id_plano_vindi > 0)
                    idPlano = this.form.id_plano_vindi;
                else
                    idPlano = this.planoAntigoToNovo(this.form.id_plano_antigo)
            } catch (e) {
                try {
                    idPlano = this.planoAntigoToNovo(this.form.id_plano_antigo)
                } catch (e) {
                    idPlano = this.planoAntigoToNovo(0)
                }
            }

            try {
                if (this.form.planoManual && this.form.planoManual.id_plano && this.form.planoManual.id_plano > 0) {
                    idPlano = this.form.planoManual.id_plano;
                }
            } catch (e) {
                console.log(e)
            }


            firebase.firestore().collection('planos').doc(idPlano.toString()).get().then((doc) => {
                if (doc.exists) {
                    this.plano = doc.data();
                }else{
                    this.plano = null
                }
            }).catch((error) => {
                this.plano = null
                console.log("Error getting document:", error);
            });

        },
        planoAntigoToNovo(id) {
            switch (id) {
                case 1:
                    return 318370
                case 2:
                    return 1
                case 3:
                    return 318369
                case 4:
                    return 2
                case 5:
                    return 2
                case 7:
                    return 318368
                case 6:
                    return 318368
                case 8:
                    return 1
                case 9:
                    return 318369
                case 10:
                    return 2
                case 11:
                    return 318370
                default:
                    return 68482
            }
        },
        configUpload(e) {
            if (e.target.files && e.target.files[0])
                this.form.empresa.logo = e.target.files[0];
        },
        buscaCep() {
            this.is_load = true;
            var cepLocal = this.form.empresa.cep.replace(/\D/g, '');
            axios.get(`https://viacep.com.br/ws/${cepLocal}/json/`).then((response) => {
                this.is_load = false;
                console.log('response', response);
                this.form.empresa.cep = response.data.cep;
                this.form.empresa.endereco = response.data.logradouro;
                this.form.empresa.bairro = response.data.bairro;
                this.form.empresa.cidade = response.data.localidade;
                this.form.empresa.estado = response.data.uf;
            }).catch((erro) => {
                this.is_load = false;
                console.log('erro', erro)
            })
        },
        save(evt) {
            evt.preventDefault();

            if (!isCNPJ(this.form.empresa.cnpj)) {
                Vue.swal({
                    title: 'CNPJ inválido',
                    text: 'Por favor, digite um CNPJ válido para a empresa',
                    type: 'error',
                    confirmButtonText: 'Ok'
                })
            }

            if (!isCPF(this.form.pessoa.cpf)) {
                Vue.swal({
                    title: 'CPF inválido',
                    text: 'Por favor, digite um CPF válido para o contato',
                    type: 'error',
                    confirmButtonText: 'Ok'
                })
            }


            this.form.pessoa.cpf = this.form.pessoa.cpf.replace(/[^0-9]/g, '')
            this.form.empresa.cnpj = this.form.empresa.cnpj.replace(/[^0-9]/g, '')

            this.form.planoManual = this.formPlanoManual;
            this.form.empresa.site = this.clearUrl(this.form.empresa.site)


            const formData = new FormData();
            if (this.logo) {
                formData.append('id_plano', this.form.id_plano);
                formData.append('logo', this.form.empresa.logo);
                formData.append('cnpj', this.form.empresa.cnpj);
            }


            this.is_load = true;
            axios.post('cadastrosUpdate', this.form, {headers: {"Access-Control-Allow-Origin": true}}).then((response) => {
                console.log(response)
                Vue.swal({
                    title: 'Cadastro atualizado com sucesso',
                    type: 'success',
                    confirmButtonText: 'Ok'
                }).then(() => {

                    window.location.href = '/associados'
                })
                if (!this.logo) this.is_load = false;
                if (this.logo) {
                    axios.post('uploadLogo', formData, {headers: {"Access-Control-Allow-Origin": true, 'Content-Type': 'multipart/form-data'}}).then(() => {
                        this.is_load = false;
                    }).catch((erro) => {
                        console.log('erro erro', erro)
                        this.is_load = false;
                    })
                }
            }).catch((erro) => {
                console.log('erro erro', erro)
                this.is_load = false;
                Vue.swal({
                    title: 'Erro ao atualizar cadastro',
                    text: erro.response.data.erro,
                    type: 'error',
                    confirmButtonText: 'Ok'
                })
            })


        },
        loadAssociados() {
            var search = this.$route.params.cnpj.replace(/[^0-9]/g, '');
            console.log('cnpj', search);
            if (search.length > 5) {
                this.is_novo = false;
                this.is_load = true;
                var consulta = firebase.firestore().collection('empresas').doc(search);
                consulta.get().then(snapshot => {
                    this.form = snapshot.data();
                    this.getPlano()
                    let idPlano = this.form.id_plano;
                    if(!idPlano) idPlano = this.planoAntigoToNovo(this.form.id_plano_antigo)
                    if(this.form.id_plano_vindi) idPlano = this.form.id_plano_vindi
                    console.log('idPlano xxx',idPlano)
                    try {
                        this.formPlanoManual = this.form.planoManual;

                        if (!this.formPlanoManual) {
                            this.formPlanoManual = {
                                ativo: (idPlano == 1 || idPlano == 2) ? 1 : 0,
                                vencimento: null,
                                id_plano: (idPlano == 1 || idPlano == 2) ? idPlano : null,
                            }
                        }else{
                            this.formPlanoManual.ativo = (idPlano == 1 || idPlano == 2) ? 1 : 0
                            this.formPlanoManual.id_plano = (idPlano == 1 || idPlano == 2) ? idPlano : null
                        }

                    } catch (e) {
                        console.log('erro', e)
                        this.formPlanoManual = {
                            ativo: 0,
                            vencimento: null,
                            id_plano: null,
                        }
                    }
                    console.log('this.form', this.form)
                    console.log('form', this.form);
                    this.is_load = false;
                }).catch(error => {
                    console.log(error);
                    this.is_load = false;
                });
            }
        },
    }
}
</script>

<style>
.required legend:after {
    content: " *";
    color: red;

}
</style>